<template>
    <el-input-number
        :controls="false"
        typeof="number"
        v-bind="$attrs"
        v-on="$listeners"
        v-model="price"
        :size="size"
        :min="min"
        :max="max"
        :precision="precision"
    />
</template>

<script>
import Util from 'js/Util';

export default {
    name: 'EfPriceInput',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        value: {
            type: Number,
            default: undefined,
        },
        size: {
            type: String,
            default: 'mini',
        },
        precision: {
            type: Number,
            default: 2,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            price: undefined,
        };
    },
    watch: {
        value: {
            handler(n, o) {
                this.price = Util.isEmpty(n) ? undefined : n;
            },
            immediate: true,
        },
        price: {
            handler(n, o) {
                this.$emit('input', n);
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.el-input-number {
    width: auto;
}

.el-input-number >>> .el-input__inner {
    text-align: left;
}
</style>

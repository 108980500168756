//公共验证规则

import Util from 'js/Util';
import StockBizCommon from 'js/StockBizCommon';

export const deptCodeValidateRule = () => {
    return [{ required: true, message: '请选择机构', trigger: 'change' }];
};

export const mobileValidateRule = (required = true) => {
    var patternReg = /^1\d{10}$/;
    return {
        required: required,
        message: '请正确输入手机号',
        trigger: ['blur', 'change'],
        validator: (rule, value, callback) => {
            if (!required && Util.isEmpty(value)) {
                callback();
            }
            if (Util.isEmpty(value)) {
                callback(new Error('请输入手机号'));
            } else if (!patternReg.test(value)) {
                callback(new Error('请正确输入手机号'));
            } else {
                callback();
            }
        },
    };
};

export const discountValidateRule = () => {
    return {
        pattern: /^(0(\.[1-9])|[1-9](\.[0-9])?|10|10(\.0))$/,
        message: '请正确输入折扣值',
        trigger: ['blur'],
    };
};

export const remarkValidateRule = (remarkMaxLength) => {
    remarkMaxLength = remarkMaxLength || 200;
    return [
        { required: true, message: '请填写备注', trigger: ['change', 'blur'] },
        {
            min: 1,
            max: remarkMaxLength,
            message: `字数不能超过${remarkMaxLength}`,
            trigger: ['change', 'blur'],
        },
    ];
};

export const remarkNotRequiredValidateRule = (remarkMaxLength) => {
    remarkMaxLength = remarkMaxLength || 200;
    return [
        {
            min: 0,
            max: remarkMaxLength,
            message: `字数不能超过${remarkMaxLength}`,
            trigger: ['change', 'blur'],
        },
    ];
};

export const goodsCodeValidateRule = () => {
    return [{ required: true, message: '请选择商品', trigger: 'change' }];
};

export const pricesValidateRule = (precision) => {
    const patternReg = new RegExp(`^[0-9]+([.][0-9]{1,${precision}})?$`);
    const reg = /^[0-9]+([.][0-9]{1,2})?$/;
    return {
        validator: (rule, val, callback) => {
            return (precision ? patternReg : reg).test(val) ? callback() : callback(new Error('请正确填写金额数值'));
        },
        trigger: ['blur'],
    };
};

export const packFactorsValidateRule = () => {
    return [
        {
            validator: (rule, val, callback) => {
                return StockBizCommon.validatePackFactor(val) ? callback() : callback(new Error('包装因子错误'));
            },
            trigger: ['blur'],
        },
    ];
};

/**
 * 重复校验
 */
class DuplicateValidateRule {
    constructor(duplicateValidateFunc, duplicateErrorMsg, clearDuplicateValFunc) {
        this.duplicateValidateFunc = duplicateValidateFunc;
        this.duplicateErrorMsg = duplicateErrorMsg;
        this.clearDuplicateValFunc = clearDuplicateValFunc;
    }

    duplicateValidateRule = (rule, val, callback) => {
        if (Util.isEmpty(val)) {
            return callback();
        }
        //正则匹配获取数值
        rule.fullField.match(/\.(\d*)\./);
        const currentIdx = Number(RegExp.$1);
        const duplicated = this.duplicateValidateFunc(currentIdx, val);
        if (duplicated === true) {
            this.clearDuplicateValFunc(currentIdx);
            return callback(new Error(this.duplicateErrorMsg));
        } else {
            return callback();
        }
    };
}

export const duplicateValidateRule = (duplicateValidateFunc, duplicateErrorMsg, clearDuplicateValFunc) => {
    return {
        validator: new DuplicateValidateRule(duplicateValidateFunc, duplicateErrorMsg, clearDuplicateValFunc)
            .duplicateValidateRule,
        trigger: ['blur'],
    };
};

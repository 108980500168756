//库存业务验证规则

import StockBizCommon from 'js/StockBizCommon';

export const abstractCodeValidateRule = () => {
    return [{ required: true, message: '请选择摘要', trigger: 'change' }];
};

export const countGreaterThanZeroValidateRule = () => {
    return {
        validator: (rule, count, callback) => {
            count > 0 ? callback() : callback(new Error('数量必须大于0'));
        },
        trigger: ['blur'],
    };
};

export const countGreaterThanEqualZeroValidateRule = () => {
    return {
        validator: (rule, count, callback) => {
            count >= 0 ? callback() : callback(new Error('数量必须大于等于0'));
        },
        trigger: ['blur'],
    };
};

export const countValidateRule = (detailParamsFunc, skuByGoodsCodeFunc) => {
    return {
        validator: new CountValidateRule(detailParamsFunc, skuByGoodsCodeFunc).countValidateRule,
        trigger: ['blur'],
    };
};

class CountValidateRule {
    constructor(detailParamsFunc, skuByGoodsCodeFunc) {
        this.detailParamsFunc = detailParamsFunc;
        this.skuByGoodsCodeFunc = skuByGoodsCodeFunc;
    }

    countValidateRule = (rule, count, callback) => {
        return StockBizCommon.validateStockBizCount(
            rule,
            count,
            callback,
            this.detailParamsFunc,
            this.skuByGoodsCodeFunc
        );
    };
}

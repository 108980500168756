<template>
    <el-input-number
        :controls="false"
        v-bind="$attrs"
        v-on="$listeners"
        v-model="count"
        :max="max"
        size="mini"
        :min="min"
        :precision="precision"
    />
</template>

<script>
import GoodsCommon from 'js/GoodsCommon';
import Util from 'js/Util';

export default {
    name: 'EfStockBizCountInput',
    model: {
        prop: 'value',
        event: 'input',
    },
    props: {
        skuType: {
            type: Number,
            default: GoodsCommon.SkuTypeEnum.STANDARD,
        },
        value: {
            type: Number,
            default: undefined,
        },
        max: {
            type: Number,
            default: 9999999,
        },
        min: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            count: undefined,
        };
    },
    watch: {
        value: {
            handler(n, o) {
                this.count = Util.isEmpty(n) ? undefined : n;
            },
            immediate: true,
        },
        count: {
            handler(n, o) {
                this.$emit('input', n);
            },
            immediate: true,
        },
    },
    computed: {
        precision() {
            const property = GoodsCommon.SkuTypeEnum.properties[this.skuType] || {};
            return property.precision || 0;
        },
    },
};
</script>

<style scoped>
.el-input-number {
    width: auto;
}

.el-input-number >>> .el-input__inner {
    text-align: left;
}
</style>
